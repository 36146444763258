import React, { useContext, useEffect, useState } from "react"
import { useDocumentContext } from "./DocumentContext"
import {
    IsUnionType,
    UnionType,
    Type,
    TypeToString,
    MatchesType,
    IsLiteralType,
    IsTypeOrUndefined,
    AreTypesAssignable,
    UndefinedType,
    IsStringLiteral,
    IsNumberLiteral,
    IsBooleanLiteral,
    Property,
} from "../../reactor/Types/Type"
import { CreateDefault } from "../../reactor/Types/Defaults"
import { PropView, PropViewMode } from "./PropView"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { RButton } from "./Buttons"
import { prettyCamel } from "../../reactor/Helpers"
import { DiagnosticView } from "./DiagnosticView"
import { ErrorContext } from "./ErrorContext"
import { useIsReadonlyField } from "./ObjectContext"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { SubstituteAndDiscriminate } from "../../reactor/ReflectionInfo"
import { StringView } from "./StringView"
import { ClientSideLocalize, Strings } from "../../packages/localization/client-side/Dictionary"
import { ColorStyles } from "../../packages/ui"
import { useEditableContext } from "../../packages/editing/EditableContext"

export function UnionView({
    obj,
    property,
    label,
    buttons,
    mode,
    isEmbedded,
}: {
    obj: any
    property: Property
    label?: string
    buttons: ToolButton[]
    mode: PropViewMode
    isEmbedded?: boolean
}) {
    const optionsAttr = property.tags?.options
    if (optionsAttr) {
        return StringView({
            obj,
            property,
            label,
            buttons,
        })
    }

    const docContext = useDocumentContext()
    const { setDirty } = useDirtyContext()
    const { versionKey } = useEditableContext()
    const [show, setShow] = useState(!label || property.tags?.expand)
    const value = obj[property.name]
    const { error } = useContext(ErrorContext)

    const optional = !!property.optional
    let type = property.type
    if (!IsUnionType(type)) throw new Error("UnionView must have union type property")

    if (optional && !AreTypesAssignable(type, UndefinedType)) {
        type = UnionType(type, UndefinedType)
    }

    const options: Type[] = []
    function findOptions(u: Type): void {
        if (IsUnionType(u)) {
            u.union.forEach(findOptions)
        } else {
            options.push(u)
        }
    }
    findOptions(type)

    const matches = options.map((x) => MatchesType(value, x, true, false, false))

    // Score matches so more specific types win over less specific types
    let bestMatch = -1
    let bestScore = 0
    for (let i = 0; i < matches.length; i++) {
        if (matches[i] === true) {
            let score = 1
            if (IsStringLiteral(options[i])) score = 2
            if (IsNumberLiteral(options[i])) score = 2
            if (IsBooleanLiteral(options[i])) score = 2
            if (score > bestScore) {
                bestScore = score
                bestMatch = i
            }
        }
    }

    let valueIndex = bestMatch

    if (valueIndex === -1) {
        const dt = SubstituteAndDiscriminate(value, type)
        if (dt !== type) {
            valueIndex = options.indexOf(dt)
            if (valueIndex === -1) {
                valueIndex = options.findIndex((opt) => AreTypesAssignable(opt, dt))
            }
        }
    }

    const [explicitTypeIndex, setExplicitTypeIndex] = useState<number | undefined>(undefined)

    useEffect(() => {
        setExplicitTypeIndex(undefined)
    }, [versionKey])

    const typeIndex = explicitTypeIndex !== undefined ? explicitTypeIndex : valueIndex

    const guessedType: Type | undefined = explicitTypeIndex !== -1 ? options[typeIndex] : undefined

    const undefinedPlaceholder = property.tags?.placeholder || ""

    const niceOptions = options.map((x) => {
        if (typeof x === "object" && x.tags) {
            const trans = x.tags.translation
            if (trans) {
                trans.en ??= TypeToString(x)
                    // remove leading and trailing quotes
                    .replace(/^"(.*)"$/, "$1")
                return prettyCamel(ClientSideLocalize(trans))
            }
        }

        return typeof x === "object" && "string" in x && x.string !== null
            ? prettyCamel(x.string)
            : prettyCamel(x === "undefined" ? undefinedPlaceholder : TypeToString(x))
    })

    const isReadonly = useIsReadonlyField(property)

    const optionIndex = typeIndex === -1 ? niceOptions.length : typeIndex

    const selector = isReadonly ? (
        <div
            style={{
                color: ColorStyles.gray[900],
                padding: 8,
                paddingLeft: 12,
                width: "100%",
                borderColor: ColorStyles.gray[300],
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 8,
            }}
        >
            {typeof value === "object" ? niceOptions[optionIndex] : prettyCamel(value)}
        </div>
    ) : (
        <select
            className="form-select"
            style={{
                backgroundColor: error ? ColorStyles.error[300] : "white",
                borderWidth: 1,
                borderColor: ColorStyles.gray[300],
                color: ColorStyles.gray[700],
                flex: 1,
                margin: 0,
            }}
            disabled={isReadonly}
            onChange={(x) => {
                const i = parseInt(x.target.value)
                obj[property.name] = CreateDefault(options[i])
                setExplicitTypeIndex(i)
                setShow(true)
                setDirty()
                if (docContext?.refresh) void docContext.refresh()
            }}
            value={typeIndex === -1 ? niceOptions.length : typeIndex}
        >
            {niceOptions.map((x: any, i: number) => (
                <option key={i} value={i}>
                    {x}
                </option>
            ))}
            {typeIndex === -1 ? (
                <option key="undefined" value={niceOptions.length}>
                    Please select...
                </option>
            ) : (
                <></>
            )}
        </select>
    )

    const isBinary = IsTypeOrUndefined(type, (x): x is Type => !IsUnionType(x))

    if (isBinary) {
        //selector = undefined
    }

    const diagnostic = (
        <DiagnosticView
            property={property}
            value={value}
            noSubtree={!guessedType}
            overrideText={!guessedType ? "Required." : undefined}
        />
    )

    return !guessedType || IsLiteralType(guessedType) ? (
        <PropRow
            label={label}
            description={property.description}
            buttons={buttons}
            isEmbedded={isEmbedded}
            badge={diagnostic}
        >
            {selector}
        </PropRow>
    ) : (
        <div>
            {!isBinary && (
                <PropRow
                    label={label}
                    description={property.description}
                    isEmbedded={isEmbedded}
                    buttons={buttons}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {selector}
                        <RButton active={show} variant="secondary" onClick={() => setShow(!show)}>
                            {!show ? Strings.Show : Strings.Hide}
                        </RButton>
                    </div>
                </PropRow>
            )}

            {(isBinary || show) && (
                <PropView
                    mode={mode}
                    obj={obj}
                    property={{
                        ...property,
                        optional: false,
                        type: guessedType,
                        description: undefined,
                    }}
                    buttons={[]}
                    isEmbedded={true}
                />
            )}

            {diagnostic}
        </div>
    )
}
