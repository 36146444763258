import React, { CSSProperties, useContext, useEffect, useState } from "react"
import { RButton } from "./Buttons"
import { PropRow } from "./PropRow"
import { PropView, PropViewMode } from "./PropView"
import { ToolButton } from "./ToolButton"
import { useIsReadonlyField } from "./ObjectContext"
import { Property } from "../../reactor/Types/Type"
import { Assert, prettyCamel } from "../../reactor/Helpers"
import { CreateDefault, UninitializedDefaults } from "../../reactor/Types/Defaults"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { Locale, LocaleKey } from "../../packages/localization/Locale"
import { useStudioLocale } from "../../packages/localization/client-side/useStudioLocale"
import { useCurrentLocale } from "../../packages/localization/client-side/useLocalize"
import { useLocalesContext } from "../../packages/localization/client-side/useLocalesContext"
import {
    ClientSideLocalize,
    Dictionary,
    Translations,
} from "../../packages/localization/client-side/Dictionary"
import { CurrentLocaleContext } from "../../packages/localization/client-side/CurrentLocaleContext"
import { DiagnosticView } from "./DiagnosticView"

export function LocalizedView({
    obj,
    label,
    property,
    buttons,
    isEmbedded,
    mode,
}: {
    obj: any
    label?: string
    property: Property
    isEmbedded?: boolean
    mode: PropViewMode
    buttons: ToolButton[]
}) {
    const type = property.type
    const name = property.name

    if (!(typeof type === "object" && "typeArgs" in type)) {
        throw new Error()
    }
    const valueType = Assert(type.typeArgs)[0]
    const [locale, setLocale] = useState<LocaleKey | undefined>(undefined)
    const { setDirty } = useDirtyContext()

    const dictionary = obj[name]
    const isReadonly = useIsReadonlyField(property)

    const placeholder =
        dictionary && typeof dictionary === "object" && !("locale" in dictionary)
            ? ClientSideLocalize(dictionary, locale)
            : undefined
    const chosenLocale = Dictionary.chooseLocale(dictionary, locale)

    return (
        <div>
            <PropRow
                isReadonly={isReadonly}
                buttons={buttons}
                isEmbedded={isEmbedded}
                description={property.description}
                badge={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <LocalePicker
                            currentFallback={chosenLocale !== locale ? chosenLocale : undefined}
                            locale={locale}
                            setLocale={setLocale}
                            hasLocales={
                                dictionary ? (Object.keys(dictionary) as any as LocaleKey[]) : []
                            }
                        />
                        <DiagnosticView property={property} value={dictionary} />
                    </div>
                }
                label={label ?? prettyCamel(name.toString())}
            >
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                        {locale &&
                            (dictionary ? (
                                <PropView
                                    key={locale.toString()}
                                    obj={dictionary}
                                    property={{
                                        name: locale.toString(),
                                        type: valueType,
                                        isReadonly: property.isReadonly,
                                        optional: true,
                                        // Inherit tags such as @multiline from parent
                                        tags: property.tags,
                                        placeholder,
                                    }}
                                    bubbleButtons={(b) => buttons.push(...b)}
                                    mode={mode}
                                    buttons={[]}
                                    isEmbedded={true}
                                />
                            ) : (
                                <RButton
                                    variant="secondary"
                                    onClick={() => {
                                        obj[name] = {
                                            [locale.valueOf()]: CreateDefault(
                                                valueType,
                                                UninitializedDefaults
                                            ),
                                        }
                                        setDirty()
                                    }}
                                >
                                    {Translations.Add()}
                                </RButton>
                            ))}
                    </div>
                </div>
            </PropRow>
        </div>
    )
}

const flagButtonStyle = {
    alignSelf: "center",
    fontSize: 24,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
}

export function LocalePicker({
    locale,
    setLocale,
    hasLocales,
    locales,
    ignoreDefault,
    currentFallback,
}: {
    locale?: LocaleKey
    setLocale: (loc: LocaleKey) => void
    hasLocales?: LocaleKey[]
    locales?: Locale[]
    ignoreDefault?: boolean
    currentFallback?: LocaleKey
}) {
    useStudioLocale()

    const currentDefaultLocale = useCurrentLocale()
    const backendLocales = useLocalesContext()

    locales ??= backendLocales ?? []

    const loc = locales?.find((x) => x.key === locale)

    useEffect(() => {
        if (!ignoreDefault && currentDefaultLocale) {
            setLocale(currentDefaultLocale)
        }
    }, [currentDefaultLocale])

    if (!locales || locales.length < 2) {
        return <></>
    }
    const fallback = locales.find((l) => l.key === currentFallback)

    return (
        <RButton
            style={flagButtonStyle}
            popup={(close) => (
                <>
                    {locales?.map((l) => (
                        <RButton
                            key={l.key.valueOf()}
                            style={(hover) => ({
                                ...flagButtonStyle,
                                backgroundColor:
                                    l.key === locale ? "#ddd" : hover ? "#eee" : undefined,
                            })}
                            onClick={() => {
                                setLocale(l.key)
                                close()
                            }}
                        >
                            {LocaleView(locales, l)}
                            {hasLocales &&
                                (hasLocales.some((x) => x === l.key) ? (
                                    <span style={{ fontSize: 12, color: "green", marginLeft: 4 }}>
                                        ✓
                                    </span>
                                ) : (
                                    <span style={{ fontSize: 12, color: "red", marginLeft: 4 }}>
                                        ✗
                                    </span>
                                ))}
                        </RButton>
                    ))}
                </>
            )}
        >
            {loc && LocaleView(locales, loc, { opacity: fallback ? 0.5 : 1 })}
            {fallback && (
                <>
                    <span
                        style={{
                            fontSize: 12,
                            color: "red",
                            marginLeft: -8,
                            marginTop: 6,
                            marginRight: 4,
                        }}
                    >
                        ✗
                    </span>
                    <div style={{ fontSize: 14 }}>→{LocaleView(locales, fallback)}</div>
                </>
            )}
        </RButton>
    )
}

function LocaleView(locales: readonly Locale[] | undefined, loc: Locale, style?: CSSProperties) {
    /* Disambiguate between multiple locales having the same flag */
    return (
        <span style={style}>
            {loc.flag}
            <span style={{ fontSize: 12, marginLeft: 4 }}>
                {locales?.some((x) => x !== loc && x.flag === loc.flag) ? loc.key : ""}
            </span>
        </span>
    )
}

export function CurrentLocalePicker() {
    const { locale, setLocale } = useContext(CurrentLocaleContext)
    return (
        <LocalePicker
            locale={locale}
            setLocale={(loc) => {
                if (setLocale) {
                    setLocale(loc)
                } else {
                    alert("Cannot change locales in this context")
                }
            }}
        />
    )
}
