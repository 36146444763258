import React from "react"
import { Property } from "../../reactor/Types/Type"
import { DiagnosticView } from "./DiagnosticView"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { ChromePicker } from "react-color"
import { useIsReadonlyField } from "./ObjectContext"
import { useDirtyContext } from "../../packages/editing/DirtyContext"

export function ColorView({
    obj,
    property,
    buttons,
    label,
    isEmbedded,
}: {
    obj: any
    property: Property
    label?: string
    buttons: ToolButton[]
    isEmbedded?: boolean
    options?: any[]
}) {
    const value = obj[property.name]
    const { setDirty } = useDirtyContext()
    const isReadonly = useIsReadonlyField(property)

    return (
        <PropRow
            isReadonly={isReadonly}
            label={label}
            buttons={buttons}
            description={property.description}
            isEmbedded={isEmbedded}
            badge={<DiagnosticView property={property} value={value} />}
        >
            <ChromePicker
                color={value || "#000"}
                onChangeComplete={(v) => {
                    if (!isReadonly) {
                        obj[property.name] = v.hex
                        setDirty()
                    }
                }}
            />
        </PropRow>
    )
}
